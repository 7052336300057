import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"

const PageHeader= (props, header) => {
          return (
                <Header>{header}</Header>
          );
        };
      
  export default PageHeader;



const Header = styled.h1`
position:relative;
font-size:2em;
font-weight:600;
margin:25px;
margin-left:0px;
text-align: left;
color:#ffffff;
text-shadow: 2px 2px 50px  ${colors.text.navigator};
background: -webkit-linear-gradient(#013474, purple);
background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`;
