import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors"

const BuiltWith = (props) => {

    return (

        <BuiltWithWrapper >
            Built with
            <StyledTech src="./assets/ETH.svg" />
            <StyledTech src="./assets/MATIC.svg" />
            <StyledTech src="./assets/COMP.svg" />
            <StyledTech src="./assets/AAVE.svg" />
            <StyledTech src="./assets/YEARN.svg" />
            and passion!
        </BuiltWithWrapper >
    );
};

export default BuiltWith;

const StyledTech = styled.img`
position:relative;
height:35px;
margin:10px;
`;

const BuiltWithWrapper = styled.div`
position:relative;
bottom:0px;
width:100%;
height:75px;
text-align:center;
color:#ffffff;
font-weight:600;
font-size:20px;
`;