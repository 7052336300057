import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"


let warnHeight = 25
const DevStatus = (props) => {
    const { warning, dispatch, uiHeight } = props
    return (
  
    <StatusRow><Pulsar /> <StatusActive>Development phase <StatusIndicator>LIVE</StatusIndicator></StatusActive></StatusRow>
                             
    );
};

export default DevStatus;


const StatusIndicator = styled.span`
color:green;
`;

const pulse = keyframes`
0% {
    box-shadow: 0 0 0 1px rgba(128, 0, 128, 0.6);
  }
100% {
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0);
  }
`;

const Pulsar = styled.div`
display:inline-block;
transform-style: preserve-3d;
background: radial-gradient(circle farthest-corner at 33% 33%, rgba(0, 0, 0, 0.85) 0%, rgba(128, 0, 128, 0.85) 80%), radial-gradient(circle farthest-corner at 45% 45%, rgba(0, 0, 0, 0) 50%, #800080 80%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: ${pulse} 2s infinite;
`;



const StatusActive = styled.div`
 display:inline-block;
 font-weight:500;
    font-size:1.5em;
    margin:25px;
    color:${colors.foreground.primary};
`;
const StatusRow = styled.div`
position:relative;
margin-left:0px;
margin-right:auto;
margin:10px;
clear: both;
`;

