import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import { NETWORK, ERC20 } from "@idecentralize/erclib";
import { hexToRgb } from "../utils/helpers";
import DevStatus from "../components/DevStatus";
//Asset and Chain should roll down the selection from erclib

const Swap = (props, footer) => {

    // see App.js for all props combined
    const { uiHeight, assetSelected, assetDefault } = props

    return (
        <PageWrapper>
            <Content height={uiHeight} >
                <Tab height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props, "Swap")}
                        {DevStatus(props)}
                        <SwaperWrapper>

                            <NetworkSelector color={hexToRgb(NETWORK[1].color)} >    
                            <Chains>chains</Chains>
                                <SelectorBG>
                                    <AssetSelector color={assetDefault ? hexToRgb(ERC20[1][assetSelected.address].color) : "#000000"}>
                                        <Amount>Amount</Amount>
                                    <Asset>  <SelectedA src={assetDefault ? `../assets/${ERC20[1][assetSelected.address].symbol}.svg` : null}/> </Asset>
                                    </AssetSelector>
                                </SelectorBG>
                            </NetworkSelector>

                            <NetworkSelector color={hexToRgb(NETWORK[137].color)}>
                            <Chains>chains</Chains>
                                <SelectorBG>
                                    <AssetSelector color={assetDefault ? hexToRgb(ERC20[137][assetSelected.address].color) : "#000000"}>
                                        <Amount>Amount</Amount>
                                        <Asset>
                                            <SelectedA src={assetDefault ? `../assets/${ERC20[137][assetSelected.address].symbol}.svg` : null}/>
                                            
                                            </Asset>
                                    </AssetSelector>
                                </SelectorBG>
                            </NetworkSelector>
                        </SwaperWrapper>
                    </StyledContainer>
                </Tab>
                {footer ? <Footer /> : "" }
            </Content>
        </PageWrapper>
    );
};

export default Swap;


const SelectedA = styled.img`
height:40px;
margin:5px;
`;

const Amount = styled.div`
position:relative;
width:100%;
height:100%;
right:0px;
border-radius:8px;
background-color: white;
`;

const Asset = styled.div`
position:absolute;
top:0px;
width:100px;
height:100%;
right:0px;
border-radius:8px;
background-color: white;
border:1px solid #000000;
`;

const Chains = styled.div`
width:300px;
height: 40px;

border-radius:5px;
border:1px solid #000000;
background-color: white;
`;

const SelectorBG = styled.div`

background-color:#000000;
position:absolute;
bottom:0px;
height:75px;
width:560px;
border-radius:7px;
margin:10px;
`;

const NetworkSelector = styled.div`
position:relative;
height:190px;
width:590px;
border-radius: 7px;
margin:5px;
padding:10px;
background-color: rgba( ${(props) => props.color[0] + ',' + props.color[1] + ',' + props.color[2] + ','} 0.5);
`;

const AssetSelector = styled.div`
height:100%;
border-radius:10px;
background-color: rgba( ${(props) => props.color[0] + ',' + props.color[1] + ',' + props.color[2] + ','} 0.5);
`;

const SwaperWrapper = styled.div`
height:420px;
width:620px;
border-radius: 10px;
margin:auto;
padding:10px;
background-color: ${colors.background.secondary};
box-shadow: 2px 2px 5px ${colors.theme.primary};
`;


const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;


