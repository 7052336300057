import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container} from "react-bootstrap";
import {colors} from "../utils/Colors"
import PageHeader from "../components/PageHeader"


const Frens = (props, footer) => {
  const {uiHeight} = props
        return (
            <PageWrapper>
                <Content height={uiHeight} >
                    <Tab id="frens" height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props,"Frens")}
                        <p>Frens is a decentralized social media platform built with the advantage of the Ethereum Network and ENS without the gas constraint.
                            with IPFS we can scale and rely on a decentralized data storage solution. Frens is a decentralized a social media that is not 
                            controlled by any central authority. Your freedom of speech is a fundamental human right. We Speak, we do it publicly and we are Frens.</p>
                           <br />
                            <p>With frens you can do much more than your usual social platform. Support a cause that matter to you without boundaries, launch your NFT project
                            and start your own venture. Send payments, start your online shopping center, report news to the world, the limit is yours to imagination.</p>

                            Say hello to my new frens.<br />

                            idecentralize.eth
                        
                    </StyledContainer>
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content >
            </PageWrapper>
        );
      };
    
export default Frens;
    

const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;


