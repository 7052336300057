import React,{useEffect, useRef} from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import ButtonsOutline from "../components/ButtonFlat";
import { addRPC } from "../store/interactions";
import { mintGreedyBot } from "../store/interactions"
import Footer from "../components/Footer";
import { assetAmountChanged } from "../store/actions";

const BotDownload = (props) => {
    const { greedyLoaded, greedyData } = props
    return (
        <BotLink>
            <select id="GreedyBot">
                <option value="0">version 1</option>
            </select>
            <br />
            <a href={greedyData.botURI}>Download Here!</a>
        </BotLink>
    )
}

const Minter = (props) => {
    const { greedyLoaded, greedyData, assetAmount, dispatch } = props
    return (
        <MintFrame>
             <GBotAmount 
            type='number'
            step="1"
            max="10"
            min="1"
            placeholder="qty"
            onChange={ (e) => dispatch(assetAmountChanged(e.target.value))}
            /><br />
            you are about to mint {assetAmount} Greedy Bot for {assetAmount * 50} Matic
            {MintButton(props)}
           
        </MintFrame>
    )
}

const GBotAmount = styled.input`
border-radius:10px;
margin:25px;
font-weight:800px;
`;


const GreedyBots = (props,footer) => {

    const { uiHeight, greedyLoaded, greedyData, account } = props
    const greedyBotsRef = useRef(null);
    useEffect(() => {
      
      });

    return (
        <Content height={uiHeight}>
        <Tab id="greedy-bots" height={uiHeight} background="./img/BotShop.jpg" ref={greedyBotsRef}>
        <StyledContainer  height={uiHeight}>

            {/* <Thumbnail src="./greedy-banner.png" /> */}
            {PageHeader(props, "Greedy Bots")}


            <Section>
                <StoryLeft>
                    <Describe>

                        A community driven project that will let you experience the most popular trends in the decentralized world.
                        From Ethereum you will bridge your tokens to a layer2 chain and deploy your very own smart-contract.
                        You will learn the fundamentals of the Ethereum Virtual Machine. Mint, trade NFTs, and even have your very own highly 
                        flexible trading bot. Create your own strategy and trade like a professional blockchain guru. You will also experience the 
                        power of decentralized governance by voting on which direction the project is going or by making a proposal yourself.
                        
                    </Describe>
                    
      
                    <PolygonConnect onClick={(e) => addRPC(137)}>Connect to polygon if it's not already done!</PolygonConnect>
                    <br />

                    {greedyLoaded  ? Minter(props) : "Loading ..."}


                    {greedyLoaded ? greedyData.balance > 0 ? BotDownload(props) : <p>You need 1 Greedy Bot to download the trading bot</p>
                        : ""}


                </StoryLeft>



            </Section>
        </StyledContainer>
        </Tab>
        {footer ? <Footer/> : ""}
        </Content>
    );
};

export default GreedyBots;


const MintButton = (props) => {
    const { dispatch, assetAmount } = props;
    return (
        <ButtonsOutline
            action={(e) => {
                mintGreedyBot(props,assetAmount)
            }}
            message="Mint Your Greedy Bot"
            color={colors.theme.greedy}
            waitForRipple={true}
        />
    )
}

const PolygonConnect = styled.a`
text-decoration:none;
color:#8f5ae8;
font-weight:800px;
&:hover{
cursor: pointer;
}
`;

const BotLink = styled.div`
position:relative;
text-align:center;
`;

const Section = styled.section`
 position:relative;
 
 `;
const MintFrame = styled.div`
display:block;
margin:25px;
text-align:center;
`;

const Describe = styled.p`
margin:15px;

`;

const StoryLeft = styled.div`
background-color:rgba(0,0,0,0.8);
border-radius:10px;
position:relative;
top:0px;
max-width:600px;
margin-left:0px;
text-align: justify;
padding:5px;
color:${colors.text.navigator};
vertical-align: top;
margin-bottom: 15px;
`;

const Storyline = styled.div`
position:absolute;
display: inline block;
top:0px;
max-width:600px;
right:0px;
margin-left:auto;
color:${colors.text.navigator};
background-color: rgba(0,0,0,0.5);
`;

const StyledContainer = styled(Container)`
position:relative;
text-align: center;
margin:auto;
min-height:${(props) => props.height}px;
`;

const Tab = styled.div`
  background-image:url(${(props) => props.background});
    background-repeat:no-repeat;
    background-size:cover;
    background-position: right bottom; /*Positioning*/
   
    position:relative;
    top:0px;
    bottom:0px;
    width:100%;
    min-height:${(props) => props.height}px;
    border:1px solid #000000;
    overflow:visible;
`;
const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
min-height:${(props) => props.height}px;

`;