import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"


const Github = (repo) => {
    return (
        <Git >
            <Link href={`https://github.com/idecentralize-finance/${repo}`} target="_blank"> <Octocat img src="./img/Octocat.png"/> See it on Github </Link>
        </Git>
    )
}

export default Github

const Git = styled.div`
margin-top:10px;
margin-bottom:10px;
border-radius:5px;
padding:5px;
background-color:${colors.background.secondary};
`;
const Octocat = styled.img`
position:relative;
left:0px;
height:40px;
`;

const Link = styled.a`
color:#ffffff !important ;
text-decoration: none;

`;