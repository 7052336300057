const config = {

    org: {
        name:"idecentralize.finance",
        short:"IDFI",
        url:"https://idecentralize.finance",
        twitter:"idecentralizeFi",
        github:"idecentralize-finance",
        discord:"bQBaEzd6zm",
        facebook:"idecentralize",
        telegram:"DecentralizedAlien"
    },

    apiKey: {
        infura: "85bebd9e43b24aeeab1c6f92afbdecad",
        ramp: "sn5jrrv4bdg3o5q6u582jrhxdkkrdfkn4xo4crtv", // rinkeby
    },

    app:{
        defaultAsset: "0x0000000000000000000000000000000000000000", //eth
        localNetworkId : 31337,                                      // hardhat
        forkedNetworkId : 1
    },

    teamMember:{
      0 :{
            name: "Ian Dorion",
            ens: "idecentralize.eth",
            location: "Canada",
            title: "Founder",
            background: "I have a wide background in different field, from Team leading trough teaching, did my studies in the field of aerospace but got hooked on IT in early 1995 and creativity is my game",
            email:"ian@idecentralize.finance",
            photo:"founderSquare.png"
        },
      1: {
            name: "Daniel Huddleston",
            ens: "dhuddly.eth",
            location: "US, ",
            title: "Tech support Node Operator",
            background: "Short description",
            email:"daniel@idecentralize.finance",
            photo:"dhuddly.jpeg"
        },
       2: {
            name: "Christian R. Ortel",
            ens: "ortel.eth",
            location: "US, Tampa FL",
            title: "Blockchain Tech",
            background: "Passionate towards blockchain space since late 2017, Blockchain technology is my everyday reading, participating to hackathons and adding Web3 to my portfolio to help decentralize the world!",
            email:"ortel@idecentralize.finance",
            photo:"ortel.jpeg"
        },

        3: {
            name: "Jonathan Compton",
            ens: "jcart.eth",
            location: "US",
            title: "Artist",
            background: "Love to draw and play instruments and just be creative in any outlet I could find since a kid. From doodles in my notebook to spray-painting abandoned buildings to professional artwork and design, I love every minute that I get to express my creativity",
            email:"jonathan@idecentralize.finance",
            photo:"jo.jpg"
        },

        4: {
            name: "Michael Vargas",
            ens: "aztecWarlord.eth",
            location: "country / location",
            title: "Moderator junior-dev",
            background: "My Blockchain journey began in early 2021 have been fervently studying and learning Blockchain technology ever since",
            email:"Michael@idecentralize.finance",
            photo:"aztechWarlord.png"
        },


    
}

  
}

export {
    config,
  }
