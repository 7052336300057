


export const formatAddress = (address) =>{
    
   let formattedAddress = address.substring(0, 6) + "..." +  address.substring(address.length - 6, address.length)
    return(
      <>{formattedAddress}</>
    )
  }


  export const hexToRgb = (hex) => {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) return normal.slice(1).map(e => parseInt(e, 16));

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);    
    if (shorthand) return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));

    return null;
}