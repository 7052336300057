import React,{useEffect, useRef} from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import TechStack from "../components/TechStack"
import BuiltWith from "../components/BuiltWith"
import GreedyBots from "./GreedyBots";
import AfterMarket from "./AfterMarket";
import Frens from "./Frens";
//import { HashRouter as Route, Link } from "react-router-dom";


function scrollToBlockBots(){
    const element = document.getElementById('greedy-bots');
    element.scrollIntoView({ behavior: 'smooth' });
}
function scrollToBlockMarket(){
    const element = document.getElementById('after-market');
    element.scrollIntoView({ behavior: 'smooth' });
}
function scrollToFrens(){
    const element = document.getElementById('frens');
    element.scrollIntoView({ behavior: 'smooth' });
}
function scrollToAliens(){
    const element = document.getElementById('aliens');
    element.scrollIntoView({ behavior: 'smooth' });
}

let warnHeight = 25
const Homepage = (props) => {
    const { warning, dispatch ,uiHeight} = props

    useEffect(() => {

      });
    
    return (
        <PageWrapper>
            <Content height={warning ? uiHeight - warnHeight : uiHeight} >

                <Tab id="home" height={uiHeight} background={'./img/fibe.jpeg'} >
                
                    <StyledContainer height={uiHeight - warnHeight}>
                     
                    {PageHeader(props,"Empowering people with decentralization")}

                    <SpotLight>

                        <StyledProject>
                            <Network src="./assets/ETH.svg"/>
                            <h3>DEFI👽</h3>
                            <Media>
                            <Link  onClick={scrollToAliens}> <Hodl src="./img/alien_demo.png" />  </Link> 
                            </Media>
                        </StyledProject>

                        <StyledProject>
                            <Network src="./assets/ETH.svg"/>
                            <h3>Frens</h3>
                            <Media>
                            <Link  onClick={scrollToFrens}> <Hodl src="./img/alien_demo.png" />  </Link> 
                            </Media>
                        </StyledProject>

                        <StyledProject>
                            <Network src="./assets/MATIC.svg"/>
                            <h3> Dream Box</h3>
                            <Media>
                            <Link  onClick={scrollToBlockMarket}> <Hodl src="./dreambox/dbx.png" />  </Link> 
                            </Media>
                        </StyledProject>

                        <StyledProject>
                            <Network src="./assets/MATIC.svg"/>
                            <h3>Greedy Bots</h3>
                            <Media>
                            <Link  onClick={scrollToBlockBots}> <Hodl src="./greedybots/greedy_logo.png" />  </Link> 
                            </Media>
                        </StyledProject>

                    </SpotLight>
                    <Board>
                            Get connected with the creator of the project with Sesion <a href="https://getsession.org/"> get it here </a>
                            Use this session is to reach Ian Decentralize <pre>05798ce469092b212528431b710b667f3dcac766f91f5a5833da6a64398de05674</pre>
                    </Board>
                       

                        <BuiltWithBanner>
                            {BuiltWith()}
                        </BuiltWithBanner>

                        <BottomBanner>
                            {TechStack(props)}
                        </BottomBanner>

                    </StyledContainer>
                </Tab>


         
                    {GreedyBots(props)}

                    {AfterMarket(props)}

                    {Frens(props)}

                <Footer />
            </Content>
        </PageWrapper>
    );
};

export default Homepage;

const Link = styled.a`
text-decoration: none;
&:hover{
cursor: pointer;
}
`;

const Network = styled.img`
position:absolute;
top:50px;
left:10px;
height:30px;
`;

const Hodl = styled.img`
max-height:200px;
max-width: 200px;

`;

const SpotLight = styled(Container)`
position:relative;
display: flex;
justify-content:space-evenly;
align-items:justify;
flex-direction: row;

`;

const Media = styled.div`
position:relative;
display: flex;
align-items: center;
  justify-content: center;
height:200px;

`;


const StyledProject = styled.div`
position:relative;
display:inline-block;
margin:25px;
width:auto;
height:250px;
width:250px;
color:#ffffff;
border-radius:5px;
justify-content: center;
vertical-align: top;
border:solid 1px ${colors.theme.primary};
box-shadow: 2px 2px 5px ${colors.theme.primary};
&:hover{
    box-shadow: 2px 2px 20px ${colors.theme.primary};
}
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
text-align: left;
min-height:${(props) => props.height}px;
`;

const PageWrapper = styled.div`
    position:relative;
    top:50px;
    bottom:0px;
    margin-bottom:0px; 
`;

const Content = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    overflow-y:auto;
    text-align:center;
`;

const Tab = styled.div`
    background-image:url(${(props) => props.background});
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    position:relative;
    border:1px solid #000000;
    min-height:${(props) => props.height}px;
    width:100%;

`;

const BottomBanner = styled.div`
position:relative;
display:block;
width:100%;
margin:auto;
margin-bottom:25px;
`;

const BuiltWithBanner = styled.div`
display:block;
position:relative;
bottom:0px;
width:100%;
margin:auto;
margin-bottom:0px;

`;

const Board = styled.div`
display:block;
position:relative;
bottom:0px;
text-align:center;
width:100%;
margin:auto;
margin-bottom:0px;
color:#ffffff;

`;