const colors = {

    background: {
        primary: "#000000",
        secondary: "#1a1a1a",
        translucid: "rgb(103,89,102,0.2)",
        translucid2: "rgb(18,20,20,0.9)"
    },
    text:{
        primary: "#000000",
        navigator: "#ffffff",
        footer: "#6b6969"
    },
    foreground:{
        primary: "#ffffff"
    },
    borders:{
        primary: "#000000"
    },
    shadows:{
        primary: "#000000"
    },
    theme:{
        primary:"#800080",
        text:"#787278",
        greedy:"#f31313"
    },
    black: "#000000"
}

export {
    colors,
  }