import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors";
import { config } from "../utils/Config";
import logo from './idecentralize.png';
import { HashRouter as Route, Link } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
// import LangSelector from "./LangSelector";
import Account from "./Account";

import {
    navToggler,
    navClose
} from "../store/actions";

const Navigator = (props) => {

    const { dispatch, navToggle } = props

    return (
        <NavigatorWrapper>
            <Navbar className="py-0" bg="dark" variant="dark" expand="lg" expanded={navToggle}>
                <Container>
                    <Route>
                        <Navbar.Brand >
                            <NavLink onClick={(e) => dispatch(navClose())} to="/">
                                <img
                                    alt=""
                                    src={logo}

                                    height="30"
                                    className="d-inline-block align-top"
                                />{' '}

                            </NavLink>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={navToggle ? (e) => dispatch(navClose()) : (e) => dispatch(navToggler())} />
                        <Navbar.Collapse id="basic-navbar-nav">

                            <Nav className="me-auto">
                               
                                <Nav.Link as={Link} to="/swap">Swap</Nav.Link>
                                <Nav.Link as={Link} to="/stake">Stake</Nav.Link>

                                <NavDropdown title="About" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/team" >Team</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/roadmap" >Roadmap</NavDropdown.Item>

                                </NavDropdown>

                                <NavDropdown title="Projects" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/nft" >DEFI👽</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/erclib" >ERCLIB</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/greedy-bots" >Greedy🤖 </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/bot" >BOT</NavDropdown.Item>

                                </NavDropdown>

                                <NavDropdown title="Socials" id="basic-nav-dropdown">
                                    <NavDropdown.Item target="_blank" href={`https://discord.gg/${config.org.discord}`}>Discord</NavDropdown.Item>
                                    <NavDropdown.Item target="_blank" href={`https://facebook.com/${config.org.facebook}`}>Facebook</NavDropdown.Item>
                                    <NavDropdown.Item target="_blank" href={`https://twitter.com/${config.org.twitter}`}>Twitter</NavDropdown.Item>

                                    <NavDropdown.Divider />

                                    <NavDropdown.Item target="_blank" href={`https://github.com/${config.org.github}`}>Github</NavDropdown.Item>

                                </NavDropdown>

                                <Nav.Link target="_blank" href="https://idecentralize.gitbook.io/aliens/" >Docs</Nav.Link>

                            </Nav>
                            <AccountNav>

                                {/* <LangSelector/> */}
                                <>{Account(props)}</>

                            </AccountNav>
                        </Navbar.Collapse>


                    </Route>
                </Container>
            </Navbar>
        </NavigatorWrapper>
    );
};

export default Navigator;


const AccountNav = styled.div`
padding-right:20px;
height: 45px;

`;


const NavLink = styled(Link)`
    text-decoration:none;
    margin:10px;
    color:${colors.text.footer};
    &:hover{
        text-decoration:none;
        color:${colors.text.navigator};
    }
`;

const NavigatorWrapper = styled.div`
    position: fixed;
    top:0px;
    right:0px;
    margin:0px !important;
    left:0px;
    text-align: center;
    height: 50px;
    font-weight: 500;
    background-color:${colors.background.primary};
    color:${colors.text.primary};
    box-shadow: 2px 2px 5px ${colors.shadows.primary};
    z-index:10;
    
`;

