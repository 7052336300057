/* global BigInt */
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { WalletLink } from "walletlink";
import { ethers } from "ethers";
import { ERC20, NETWORK } from "@idecentralize/erclib";
import { config } from "../utils/Config";
import Web3Modal from "web3modal";

import spacePortABI from '../abis/IDFISpacePort.json'
import protocolABI from '../abis/ProtocolInterfacer.json'
import greedyBotABI from '../abis/GreedyBots.json'
import dreamBoxABI from '../abis/DreamBox.json'



import axios from 'axios';
import { create } from 'ipfs-http-client'
import { setupENS } from "@ensdomains/ui";
import Resolution from "@unstoppabledomains/resolution";

import {
  loadAccount,
  loadWarning,
  loadWeb3,
  assetSelectedChanged,
  setDefaultAsset,
  disconnected,
  nftContractLoaded,
  setSpicePrice,
  vaultLoaded,
  greedyLoaded,
  dreamboxLoaded,
  greedyBalance,
  greedyBotURI
} from "./actions"




const ENDPOINT = "ws://127.0.0.1:9998";

/// TODO MOVE TO REGISTRY 
const spaceportEthereum = "0x3347B4d90ebe72BeFb30444C9966B2B990aE9FcB"

//GREEDY BOT
const greadybotPolygon = "0x5265535c70664EE54c8584bf7Fe62bBD2558A25C"

// DREAMBOX

const dreamBoxAddress = "0x28786aCA46DA4971a0Ee5Ef2779922CE40434b35"

// GLOBALS VARIABLE
let WEB3, PROVIDER
let ACCOUNT = []
let GREEDY, DREAMBOX
// WEB 3 PROVIDER
const providerOptions = {

  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: config.apiKey.infura,

    },

  },

  'custom-coinbase': {
    display: {
      logo: './img/coinbase.png',
      name: 'Coinbase',
      description: 'Scan with WalletLink to connect',
    },
    options: {
      appName: 'idecentralize.finance', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${config.apiKey.infura}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },



}

// GNOSIS SAFE APP
const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions,
  theme: "dark",
});

/////////////////////////////////////////////////////////////////
/// WALLET CONNECT WHEN REQUESTED BY USER
export const loadingWeb3 = async (dispatch) => {

  web3Modal.clearCachedProvider();
  PROVIDER = await web3Modal.connect();
  WEB3 = new Web3(PROVIDER);

  await _loadAccount(dispatch)
  // reload on network change
  PROVIDER.on("chainChanged", (chainId) => {
    window.location.reload();
  });

  PROVIDER.on("accountsChanged", async (account) => {
    // disconnect or load account
    if (account.length === 0) {
      web3Modal.clearCachedProvider()
      dispatch(disconnected())

    }
    else {
      await _loadAccount(dispatch)
    }
  });
  // disconnect
  PROVIDER.on("end", () => {
    web3Modal.clearCachedProvider()
    dispatch(disconnected())

  });
  // testnet warning
  if (NETWORK[ACCOUNT.chain].type == "Testnet") {
    dispatch(loadWarning(' ' + NETWORK[ACCOUNT.chain].network + ' ' + NETWORK[ACCOUNT.chain].type))
  }
  return
}

/////////////////////////////////////////////////////////////////
/// AUTO LOAD WEB3 IF ALREADY CONNECTED
export const checkForWeb3 = async (dispatch) => {

  if (web3Modal.cachedProvider) {
    if (window.web3) {
      try {
        // try to avoid metamask pop-up
        PROVIDER = await web3Modal.connect()
        WEB3 = new Web3(PROVIDER);

        await _loadAccount(dispatch)
      } catch (e) {
        console.log('error : Web3Modal catch:', e)
        return
      }

      PROVIDER.on("chainChanged", async (chainId) => {
        window.location.reload();

      });

      PROVIDER.on("accountsChanged", async (account) => {

        if (account.length === 0) {
          console.log('disconnected');
          web3Modal.clearCachedProvider()
          dispatch(disconnected())

        }
        else {
          await _loadAccount(dispatch)
        }
      });
      PROVIDER.on("disconnect", () => {
        console.log('disconnected');
        web3Modal.clearCachedProvider()
        dispatch(disconnected())

      });

      if (NETWORK[ACCOUNT.chainId].type == "Testnet") {

        dispatch(loadWarning(' ' + NETWORK[ACCOUNT.chainId].network + ' ' + NETWORK[ACCOUNT.chainId].type))
      }


    } else {
      // web3 not supported
      console.log('NO WEB3 DETECTED')
    }
  } else {
    // no web3Modal cache
    console.log('NO WEB3 CACHED')
  }
}


/////////////////////////////////////////////////////////////////
/// add a custom RPC to Metamask
export const addRPC = async (id) => {
  id = parseInt(id)
  window.ethereum.request(
    {
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x' + NETWORK[id].id.toString(16),
          chainName: NETWORK[id].network + "" + NETWORK[id].type,
          nativeCurrency: {
            name: NETWORK[id].chain,
            symbol: NETWORK[id].chain,
            decimals: 18
          },
          rpcUrls: [NETWORK[id].rpc],
          blockExplorerUrls: [NETWORK[id].explorer]
        }
      ]
    })

}

/////////////////////////////////////////////////////////////////
/// add an asset to Metamask
export const addToMetamask = async (token) => {
  console.log(token)
  // const tokenAddress = token.options.address
  // const tokenSymbol = await token.methods.symbol().call() 
  // const tokenDecimals = await token.methods.decimals().call() 
  // const tokenImage = 'https://madeindreams.ca/logo.svg';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: token.address, // The address that the token is at.
          symbol: token.symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: token.decimals, // The number of decimals in the token
          image: `https://idecentralize.finance/assets/${token.symbol}.svg`, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }

}



/////////////////////////////////////////////////////////////////
/// Load the account data and balances
const _loadAccount = async (dispatch) => {
  const unstoppable = "0xa9a6A3626993D487d2Dbda3173cf58cA1a9D9e9f";

  dispatch(disconnected())
  const accounts = await WEB3.eth.getAccounts()
  const chainId = await WEB3.eth.getChainId()
  const balance = parseFloat(ethers.utils.formatEther(await WEB3.eth.getBalance(accounts[0])).toString()).toFixed(3)
  const resolution = new Resolution();


  // ETHEREUM WITH ENS
  if (chainId == 1) {
    const { registrar, ens } = await setupENS()
    const name = await ens.getName(accounts[0])
    const owner = await ens.getOwner(name.name)
    const avatar = await ens.getText(name.name, 'avatar')
    const twitter = await ens.getText(name.name, 'com.twitter')
    const github = await ens.getText(name.name, 'com.github')
    const frens = await ens.getText(name.name, 'frens')

    let profile = await getIPFSFrens(frens)


    console.log("profile",profile)


    ACCOUNT = {
      name: name.name,
      address: accounts[0],
      avatar: avatar,
      twitter: twitter,
      github: github,
      chainId: chainId,
      balance: balance,
      frens: frens

    }


  } else if (chainId == 137) {

    ACCOUNT = {
      name: null,
      address: accounts[0],
      avatar: '',
      twitter: null,
      github: null,
      chainId: chainId,
      balance: balance

    }


  } else if (chainId == 4){

    ACCOUNT = {
      name: null,
      address: accounts[0],
      avatar: '',
      twitter: null,
      github: null,
      chainId: chainId,
      balance: balance

    }


  }

  dispatch(loadAccount(ACCOUNT))
  loadDefaultAssets(dispatch, ACCOUNT["chain"])
  dispatch(loadWeb3(WEB3))

  // loadVault(dispatch, ACCOUNT["chain"])
  if (chainId == 137) {
    loadGreedyBots(dispatch);
  }

  if (chainId == 4) {
    loadDreamBox(dispatch);
  }

  return

}

/////////////////////////////////////////////////////////////////
///  GET IPFS FRENS CONTENT FROM IPFS.IO
///
async function getIPFSFrens(cid) {

  fetch('https://ipfs.io/ipns/' + cid)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      data.posts[0] = { test: "testing" }
      data.posts[1] = { test: "testing2" }
      console.log(data)

      updateIPFSFrens(data)
      return data
    }

    );
}

async function updateIPFSFrens(data) {

  const ipfs = create('http://127.0.0.1:5001')
  let stringData = JSON.stringify(data)

  await ipfs.add(stringData, { pin: true }).then(
    (res) => { 
      let newProfileCID = res.path
      ipfs.name.publish(newProfileCID,{'key':'frens'})
    
    }
  )

}
/////////////////////////////////////////////////////////////////
///  LOAD IDFI VAULT
///
export const loadVault = async (dispatch, chain, alien) => {
  alien = 2
  // load vault for right chain
  console.log("Loading vault on chain :", chain)

  const spaceport = new WEB3.eth.Contract(spacePortABI.abi, spaceportEthereum)

  let len = await spaceport.methods.poolLength().call()
  let totalAlloc = await spaceport.methods.totalAllocPoint().call()
  console.log("Total Allocation", totalAlloc)
  console.log("Pools", len)

  // pull each pool data
  let pools = []

  let i
  for (i = 0; i < len; i++) {

    let info = await spaceport.methods.poolInfo(i).call()
    let currentPrice = await spaceport.methods.currentSharePrice(i).call()
    console.log("CURRENT SHARE PRICE", currentPrice)

    const protocol = new WEB3.eth.Contract(protocolABI.abi, info.vault)
    let protocolName = await protocol.methods.protocol().call()
    console.log("protocol Name :", protocolName)
    let collateral = await protocol.methods.currentCollateral(info.asset).call()
    console.log("Collateral :", collateral)
    // let totalShares = await vault.methods.totalShares(info.asset).call()
    // console.log("total shares", totalShares)


    // let rawSharePrice = await vault.methods._getPricePerShare(info.asset).call()
    // let sharePrice = ethers.utils.formatUnits(rawSharePrice)
    // console.log("Share Price :",sharePrice);
    // let collateral = await vault.methods.collateral(info.asset, protocolId).call()
    // console.log("Share Price", sharePrice)

    // let protocol
    let yieldData = { apy: 0 }

    switch (protocolName) {
      case "COMP":

        yieldData = await getCompoundAPYForAsset(info.asset, collateral)
        break
      case "AAVE":

        yieldData = await getAaveAPYForAsset(info.asset, collateral)
        break
      case "YEARN":

        yieldData = await getYearnAPYForAsset(info.asset, collateral)  // <------ Yearn APY
        break
    }


    // console.log("distribution",  parseFloat(info.allocPoint / totalAlloc * 100 ).toFixed(2) )
    let spiceReward = parseFloat(ethers.utils.parseEther("0.0")).toFixed(3)
    let alienValue = 0

    if (alien) {
      //  console.log("we have an alien")
      let rawAlienShares = await spaceport.methods.alienInfo(i, alien).call()
      console.log("Raw Aliens share", rawAlienShares.amount)


      let alienShares = ethers.utils.formatUnits(rawAlienShares.amount)

      alienValue = alienShares * ethers.utils.formatUnits(currentPrice, ERC20[1][info.asset].decimals)

      let pendingSpice = await spaceport.methods.pendingSpice(i, alien).call()

      console.log("PENDING", pendingSpice)
      spiceReward = ethers.utils.formatEther(pendingSpice)
      console.log("SPICE REWARD", spiceReward)
    }

    // // console.log(pendingSpice)

    let data = {
      chainId: chain,
      asset: info.asset,
      allocation: info.allocPoint,
      protocol: protocolName,
      yieldData: yieldData,
      pendingSpice: spiceReward,
      alienValue: parseFloat(alienValue).toFixed(2),
      spiceDist: parseFloat(info.allocPoint / totalAlloc * 100).toFixed(2)
    }

    pools.push(data)

  }

  dispatch(vaultLoaded(pools))
  // dispatch(assetSelectedChanged(ERC20[ACCOUNT.chain === config.app.localNetworkId ? config.app.forkedNetworkId : ACCOUNT.chain][config.app.defaultAsset]))
  // dispatch(setDefaultAsset())

  return
}



/////////////////////////////////////////////////////////////////
///  asset select
///
export const loadDefaultAssets = async (dispatch, chain) => {
  dispatch(assetSelectedChanged(ERC20[ACCOUNT.chainId === config.app.localNetworkId ? config.app.forkedNetworkId : ACCOUNT.chainId][config.app.defaultAsset]))
  dispatch(setDefaultAsset())
  return
}

export const loadSelectedAsset = (dispatch, asset, showAmount) => {
  if (!showAmount) {
    // it's erclib add to metamask
    addToMetamask(asset)
  }
  dispatch(assetSelectedChanged(asset))
}

export const loadNFTContract = async (dispatch) => {
  dispatch(nftContractLoaded())
  return
}


export const socketConnect = async (dispatch) => {
  const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
  socket.on("heartbeat", data => {
    console.log("DATA FROM API :", data)
  })
}


export const spicePrice = async (dispatch) => {
  const prices = ['30412', '30298', '30679']
  setInterval(function () { dispatch(setSpicePrice(parseFloat(prices[parseInt(Math.random() * (2 - 0) + 0)]))); }, 3000);
  dispatch(setSpicePrice(prices[Math.random() * (2 - 0) + 0]))
  //console.log(  parseInt(Math.random() * (2 - 0) + 0)  )

}










/////////////////////////////////////////////////////////////////
/// PROTOCOL API FOR APY
export const getYearnAPYForAsset = async (asset, collateral) => {

  let data
  const instance = axios.create({
    method: 'GET',
    uri: 'https://api.yearn.finance',
    timeout: 3000,
    params: {
      // 'api-key':null
    },
    headers: {

    },
    json: true,
    gzip: true
  });

  await instance.get('https://api.yearn.finance/v1/chains/1/vaults/all').then(async (response) => {

    response.data.forEach(yAsset => {
      if (WEB3.utils.toChecksumAddress(collateral) === WEB3.utils.toChecksumAddress(yAsset.address)) {
        let assetData = {
          address: yAsset.address,
          apy: yAsset.apy.net_apy,
          price: yAsset.tvl.price
        }
        data = assetData
      }
    });
  })

  return data

}


export const getAaveAPYForAsset = async (asset, collateral) => {

  let data
  const instance = axios.create({
    method: 'GET',
    uri: 'https://aave-api-v2.aave.com/',
    timeout: 3000,
    params: {
      poolId: "0xB53C1a33016B2DC2fF3653530bfF1848a515c8c5",

    },
    headers: {

    },
    json: true,
    gzip: true
  });

  await instance.get('https://aave-api-v2.aave.com/data/liquidity/v2').then(async (response) => {
    response.data.forEach(aAsset => {
      if (WEB3.utils.toChecksumAddress(collateral) === WEB3.utils.toChecksumAddress(aAsset.aTokenAddress)) {
        let assetData = {
          address: aAsset.aTokenAddress,
          apy: aAsset.liquidityRate,
          price: aAsset.referenceItem.priceInUsd
        }
        data = assetData
      }
    });
  })

  return data

}


export const getCompoundAPYForAsset = async (asset, collateral) => {

  let data
  const instance = axios.create({
    method: 'GET',
    uri: 'https://api.compound.finance/',
    timeout: 3000,
    params: {
      address: [asset]

    },
    headers: {

    },
    json: true,
    gzip: true
  });

  await instance.get('https://api.compound.finance/api/v2/ctoken?address[]=' + asset).then(async (response) => {
    // console.log(response)
    response.data.cToken.forEach(cAsset => {
      //console.log(cAsset)
      if (WEB3.utils.toChecksumAddress(collateral) === WEB3.utils.toChecksumAddress(cAsset.token_address)) {
        let assetData = {
          address: cAsset.token_address,
          apy: cAsset.supply_rate.value,
          price: 0
        }
        data = assetData
      }
    });
  })

  return data

}





/////////////////////////////////////////////////////////////////////////////
/// GRREDY_BOTS
export const mintGreedyBot = async (props,qty) => {

  let whitelisted = await GREEDY.methods.isWhitelist(ACCOUNT.address).call();
  let price;
  let presaleEnd = await GREEDY.methods.preSaleEnd().call()
  let block = await WEB3.eth.getBlockNumber()

  console.log("block", block)
  if (block >= presaleEnd) {
    // block is over preslae end
    price = 50 * qty

  } else {
    if (whitelisted) {
      price = '37500000000000000000'
    } else {
      alert('You are not eligible to mint the pre-sale')
      return
    }

  }
  console.log("Minting", ACCOUNT.address)
  await GREEDY.methods.mint(ACCOUNT.address, qty).send({ from: ACCOUNT.address, value: ethers.utils.parseEther(price.toString()) })
}




export const loadGreedyBots = async (dispatch) => {

  GREEDY = new WEB3.eth.Contract(greedyBotABI.abi, greadybotPolygon);
  let block = await WEB3.eth.getBlockNumber()
  let presaleEnd = await GREEDY.methods.preSaleEnd().call()
  let balance = await GREEDY.methods.balanceOf(ACCOUNT.address).call()

  let botURI = await GREEDY.methods.botURI(1).call({ from: ACCOUNT.address })
  let alreadyMintedWhiteList = await GREEDY.methods.mintedPresale(ACCOUNT.address).call();
  let whitelisted = await GREEDY.methods.isWhitelist(ACCOUNT.address).call();
  console.log(whitelisted)

  let currentPrice = (block >= presaleEnd) ? '50000000000000000000' : '37500000000000000000'
  let presaleOver = (block >= presaleEnd) ? true : false
  let greedyData = {

    currentBlock: block,
    presaleOver: presaleOver,
    balance: balance,
    botURI: botURI,
    wlBurned: alreadyMintedWhiteList,
    isWl: whitelisted,
    currentPrice: currentPrice

  }


  dispatch(greedyLoaded(greedyData))

}

export const loadDreamBox = async (dispatch) => {

  DREAMBOX = new WEB3.eth.Contract(dreamBoxABI.abi, dreamBoxAddress);
 

 // let balance = await GREEDY.methods.balanceOf(ACCOUNT.address).call()
 let balance = 1

  let dreamboxData = {

    greedyOwner: balance

  }


  dispatch(dreamboxLoaded(dreamboxData))

}