import React,{useEffect} from "react";
import styled from "styled-components";
import {colors} from "../../utils/Colors"
import Numerator from "../../components/Numerator";
import art1 from './src/components/alienWorldBg.jpeg'
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer"
import PageHeader from "../../components/PageHeader"
import ButtonsOutline from "../../components/ButtonFlat";

let warnHeight = 25


const Nft = (props, footer) => {
 const {dispatch, web3, warning, uiHeight} = props

    return (
     
      <PageWrapper>
      <Content height={warning ? uiHeight - warnHeight: uiHeight} >
          <Tab height={warning ?uiHeight- warnHeight : uiHeight} >



              <StyledContainer>

            <SpicePrice>
                {Numerator(props)}
            </SpicePrice>

                {PageHeader(props, "Landing soon")}

                <NoToken>⚠️ No Tokens nor NFT have been deployed on mainnet yet. Be advise! ⚠️</NoToken>
             
                <StyledDeck>
                    <video  width="550" loop autoPlay muted>
                      <source src="./mp4/Alien.mp4" type ="video/mp4" />
                    </video>
                  <Alpha>
                    <div>IDFI ALPHA GOVERNOR</div>
                    <IDFI src="./assets/IDFI.svg"/>
                    <div>100 IDFI Governance Token</div>
                    <div>Exclusive SPICE Airdrop</div>
                    <IDFI src="./assets/SPICE.svg"/>
                    <div>Own Idecentralize.finance now!</div>
                    <div></div>
                    {MintButton(props)}
                  </Alpha>

                  
                </StyledDeck>


                </StyledContainer>    
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content>
            </PageWrapper>
       
    );
  };

export default Nft;

const NoToken = styled.div`
font-size: 1.5em;
color:#ffffff;
`;

const MintButton  = (props) =>{
  const { dispatch } = props;
  return(
      <ButtonsOutline
      action={() => {
        //loadingWeb3(dispatch);
      }}
      message="Mint"
      //icon={icon}
      waitForRipple={true}
    />
  )
}




const IDFI = styled.img`
height:100px;
`;

const Alpha = styled.div`
padding:3px;
position:absolute;
width:400px;

right:0px;
top:0px;
height:400px;
color:#ffffff;
font-weight:600;
font-size: 1.5em;
text-align: center;

`;

const StyledContainer = styled(Container)`
text-align:center;

`;


const SpicePrice = styled.div`
position:absolute;
right:50px;
top:10px;
color:#ffffff;
`;






const StyledDeck = styled.div`
position:relative;
height:425px;
width:1000px;
text-align: left;
margin:auto;
border-radius:10px;
background-color:${colors.background.secondary};
padding:25px;
`;

const StyledNFT = styled.div`
position:relative;
float:left;
border-radius:5px;

margin-top: ${(props) => props.top};
height:400px;
width:425px;
text-align:center;
transform: rotate( ${(props) => props.deg} );
z-index: ${(props) => props.layer};
background-color:#2a282a;
box-shadow:inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0  rgba(0,0,0,0.7),2px 2px 50px  ${colors.theme.primary};

`;



const PageWrapper = styled.div`
    //background-image:url(${art1});
    background-repeat: no-repeat;
    background-position: center;
    background-position: top right;
    background-size:cover;
    position: absolute;
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
    width: 100%;
    text-align: center;
    color:${colors.text.primary};
    transition: background-image 1s;
    
`;


const Content = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    overflow-y:auto;
`;

const Tab = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    top:0px;
    bottom:60px;
    height:${(props) => props.height}px;
    overflow-y:auto;
  
`;