import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container} from "react-bootstrap";
import {colors} from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import Auctions from "../components/dreambox/Auctions";

const AfterMarket = (props,footer) => {
  const {uiHeight} = props
        return (
            
                <Content height={uiHeight} >
                    <Tab id="after-market" height={uiHeight} background='./img/market.jpeg' >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props,"DreamBox")}
                        <Describe>

                            The DreamBox is a decentralized NFT market place on Polygon where you can trade your NFTs. You can sell your NFTs, create auctions,
                            bountys and even trade one NFT for another. The market model is base on a traditional trading mechanism like we used to trade our sport cards.

                        </Describe>


                       

                    </StyledContainer>
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content >
        
        );
      };
    
export default AfterMarket;
    
const Describe = styled.p`
margin:15px;
width: 600px;
text-align:justify;
color:#ffffff;
`;
const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
 background-image:url(${(props) => props.background});
    background-repeat:no-repeat;
    background-size:cover;
    background-position: 0px 0px;
    position:relative;
    top:0px;
    bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    border:1px solid #000000;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;


