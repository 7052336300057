import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import Pool from "../components/pools/Pools"




const Stake = (props, footer) => {
    const { uiHeight, vaultLoaded, vaultData } = props
    return (
        <PageWrapper>
            <Content height={uiHeight} >
                <Tab height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props, "SPICE FARMING")}


                        {vaultLoaded ? vaultData.map( (data) => Pool(props,data) ): "Loading Pools"}


                    </StyledContainer>
                </Tab>
                {footer ? <Footer /> : "" }
            </Content>
        </PageWrapper>
    );
};

export default Stake;

const Pools = styled.div`
 position:relative;
display:inline-block;
width:350px;
height:200px;
border-radius:10px;
border:solid 1px ${colors.theme.primary};
box-shadow: 2px 2px 5px ${colors.theme.primary};
background-color: ${colors.background.secondary};
`;
const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;


