import React,{ useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {colors} from "./utils/Colors";
import img from "./img/shop_wide.jpg"
import "./app.css";
import { useTranslation} from "react-i18next";
import Navigator from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Switch, Route} from "react-router-dom";
import Warning from "./components/Warning";
import Homepage from './pages/Homepage';
import Team from './pages/Team';
import Nft from "./project/nft/Nft";
import Roadmap from "./pages/Roadmap";
import BOT from "./pages/BOT";
import Swap from "./pages/Swap";
import Stake from "./pages/Stake";
import ErclibPage from "./pages/Erclib";
import GreedyBots from "./pages/GreedyBots";

import{
  web3Selector,
  web3InstanceSelector,
  warningSelector, 
  warningDataSelector,
  accountSelector,
  toggleSelector,
  nftContractSelector,
  nftContractDataSelector,
  spicePriceSelector,
  assetAmountSelector,
  assetDefaultSelector,
  assetDropedSelector,
  assetSelectedSelector,
  uiHeightSelector,
  vaultLoadedSelector,
  vaultDataSelector,
  greedyDataSelector,
  greedyLoadedSelector,

} from "./store/selectors"

import {
   checkForWeb3,

 } from "./store/interactions"

 import { windowSize } from "./store/actions";

let _height = window.innerHeight 

function App() {

const dispatch = useDispatch();
const {t} = useTranslation('common');
const warning = useSelector(warningSelector)
const warningData = useSelector(warningDataSelector)
const web3 = useSelector(web3Selector)
const web3Instance = useSelector(web3InstanceSelector)
const account = useSelector(accountSelector)
const navToggle = useSelector(toggleSelector)
const nftContract = useSelector(nftContractSelector)
const nftContractData = useSelector(nftContractDataSelector)
const spice = useSelector(spicePriceSelector)
const assetSelected = useSelector(assetSelectedSelector)
const assetAmount = useSelector(assetAmountSelector)
const assetDrop = useSelector(assetDropedSelector)
const assetDefault = useSelector(assetDefaultSelector)
const uiHeight = useSelector(uiHeightSelector)
const vaultLoaded = useSelector(vaultLoadedSelector)
const vaultData = useSelector(vaultDataSelector)
const greedyData = useSelector(greedyDataSelector)
const greedyLoaded = useSelector(greedyLoadedSelector)


const props ={
  dispatch,
  t,
  web3,
  web3Instance,
  account,
  warning,
  warningData,
  navToggle,
  nftContract,
  nftContractData,
  spice,
  assetSelected,
  assetAmount,
  assetDefault,
  assetDrop,
  uiHeight,
  vaultLoaded,
  vaultData,
  greedyLoaded,
  greedyData

}

useEffect( () => {
  ( async () => {
   await checkForWeb3(dispatch)
   //await loadNFTContract(dispatch)
   // spice price
  // const testing = await spicePrice(dispatch) 
   //await socketConnect(dispatch) 

   // UI resize the 50 is the navbar

   dispatch(windowSize(window.innerHeight-50))
   window.addEventListener('resize', () => {
    dispatch(windowSize(window.innerHeight-50))
    console.log("UI Height",uiHeight)
   })

  })(dispatch)
  }, [dispatch]);
  
  return (
    <DappWrapper >

      {Navigator(props)}
    
      <StyledRouter height={_height}>
      {warning ? Warning(props) : null}

        <Router>
   
          <Switch >

          <Route path="/roadmap">
          <TabWrapper > {Roadmap(props)}</TabWrapper > 
            </Route>

            <Element path="/bot">
              <TabWrapper > {BOT(props)}</TabWrapper > 
            </Element>

            <Route path="/swap">
              <TabWrapper > {Swap(props)}</TabWrapper > 
            </Route>

            <Route path="/stake">
              <TabWrapper > {Stake(props)}</TabWrapper > 
            </Route>

            <Route path="/nft">
              <TabWrapper >{ web3 ? Nft(props): "PLEASE CONNECT"}</TabWrapper > 
            </Route>

            <Element path="/greedy-bots" >
              <TabWrapper > {GreedyBots(props,true)}  </TabWrapper> 
            </Element>

            <Element path="/erclib">
            <TabWrapper >{ErclibPage(props)}</TabWrapper>
            </Element>

            <Route path="/team">
            <TabWrapper >{Team(props)}</TabWrapper>
            </Route>

            <Route path="/">
              {Homepage(props)}
            </Route>

          </Switch>
 
        </Router>
    
      </StyledRouter>
      
    </DappWrapper >
  );
}

export default App;

const Element = styled(Route)`
overflow: scroll;
`;

const StyledRouter = styled.div`
position:relative;
width:100%;
top:0px;
min-height:${(props) => props.height}px;

`;

const DappWrapper = styled.div`
position:relative;
top:0px;
left:0px;
min-height:100%;
right:0px;
background:${colors.background.primary};
`;

const TabWrapper = styled.div`
    position:relative;
    top:50px;
    bottom:0px;
    margin-bottom:0px; 
    min-height:${(props) => props.height}px;

`;
