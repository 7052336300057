import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/Colors"
import { ERC20 } from "@idecentralize/erclib";
import Numerator from "../../components/Numerator";
import ButtonsOutline from "./../ButtonFlat";

function formatAPY(input) {

    return parseFloat(input).toFixed(2)
}

const Pool = (props,pool) => {
    console.log(pool.protocol)
    console.log(ERC20[1][pool.asset])
    return (

        <Pools key={pool.asset}>

            <Asset src={`./assets/${ERC20[pool.chainId == 31337 ? 1 : pool.chain][pool.asset].symbol}.svg`} />
            <PoolAsset src={`./assets/${pool.protocol}.svg`} />

            <SpicePrice>
                {Numerator(pool.pendingSpice)}
            </SpicePrice>

            <Yield>
                <Apy>{formatAPY(pool.yieldData.apy * 100)}% APY</Apy>
                <Dist>{pool.spiceDist}%</Dist>
            </Yield>
            <AlienValue>{pool.alienValue}</AlienValue>
            <ButtonRow >
            {Deposit(props)}
            </ButtonRow >
                
        </Pools>
    );


}



export default Pool;

const Deposit = (props) => {
    const { dispatch } = props;
    return (
        <ButtonsOutline
            action={() => {
              //  loadingWeb3(dispatch);
            }}
            message="Deposit"
           // icon={}
            waitForRipple={true}
        />
    )
}

const withdraw = (props) => {
    const { dispatch } = props;
    return (
        <ButtonsOutline
            action={() => {
               // loadingWeb3(dispatch);
            }}
            message="Connect"
           // icon={icon}
            waitForRipple={true}
        />
    )
}

const AlienValue = styled.div`
position:absolute;
bottom:50px;
text-align: center;
width: 100%;
margin:5px;
`;

const ButtonRow = styled.div`
    position:absolute;
    bottom:0px;
    width:100%;
    height:50px;

`;

const SpicePrice = styled.div`
position:absolute;
top:0px;
right:50px;
margin:5px;
color:#ffffff;
`;

const Yield = styled.div`
position:absolute;
top:60px;
width:100%;
margin:5px;
color:#ffffff;

`


const PoolAsset = styled.img`
position:absolute;
left:20px;
height:40px;
margin:5px;

margin-right:auto;

`;

const Asset = styled.img`
position:absolute;
left:0px;
height:40px;
margin:5px;

margin-right:auto;
z-index:2;

`;
const Apy = styled.div`
position:relative;
margin:5px;
margin:auto;
width:150px;

`;
const Dist = styled.div`
position:relative;
margin:auto;
width:150px;

`;
const Pools = styled.div`
 position:relative;
display:inline-block;
margin:5px;
align-items:center;
text-align:center;
width:350px;
height:200px;
border-radius:10px;
border:solid 1px ${colors.theme.primary};
box-shadow: 2px 2px 5px ${colors.theme.primary};
background-color: ${colors.background.secondary};
color:#ffffff;
`;
