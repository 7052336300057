import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors"



const TechStack = (props) => {

    return (

        <TechStackWrapper >
            <StyledTech src="./tech/oz.svg" />
            <StyledTech src="./tech/oz_defender_dark.svg" />
            <StyledTech src="./tech/ipfs_logo.png" />
        </TechStackWrapper >
    );
};

export default TechStack;

const StyledTech = styled.img`
height:30px;
margin:10px;
`;

const TechStackWrapper = styled.div`
position:relative;
bottom: 0px;
width:100%;
height:75px;
text-align:center;
`;
