import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container} from "react-bootstrap";
import {colors} from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import Erclib from "../components/erclib/Erclib"
import Npm from "../components/Npm"
import Github from "../components/Github";

const ErclibPage = (props, footer) => {
  const {uiHeight} = props
        return (
            <PageWrapper>
                <Content height={uiHeight} >
                    <Tab height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props,"ERCLib")}

                      
                            <DevelopmentSample>{Erclib(props)}</DevelopmentSample>

                            <Section>ERClib</Section>

                            <Storyline>
                                The ERClib was designed to help developers deal with multiple assets on different networks and their collaterals on different protocols.
                                The tool above uses the ERClib, it will allow you to add assets and networks to Metamask with a few click. The library is updated frequently and can be install with
                                NPM and can be found on Github. Learn how to use it with the Pulsar.

                                {Npm("npm i @idecentralize/erclib")}

                                {Github("erclib")}
                                 
                              
                            </Storyline>
                        

                    </StyledContainer>
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content>
            </PageWrapper>
        );
      };
    
export default ErclibPage;
const Section = styled.h1`
position:relative;
max-width:300px;
margin:auto;
background-color:${colors.theme.primary};
`;

const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
 
    position:relative;
    top:0px;
    bottom:0px;
    width:100%;
     min-height:${(props) => props.height}px;
    overflow-y:visible; 
`;
const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;

const Storyline = styled.div`
position:relative;
max-width:300px;
margin:auto;
padding:5px;
color:${colors.text.navigator};
background-color: rgba(0,0,0,0.5);
`;

const DevelopmentSample = styled.div`
position:relative;
text-align: left;
max-width:300px;
margin:auto;
margin-bottom:25px;;
`;

