import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "react-bootstrap";
import { colors } from "../utils/Colors"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"


let warnHeight = 25
const RoadMap = (props, footer) => {
    const { warning, dispatch, uiHeight } = props
    return (
        <PageWrapper>
            <Content height={warning ? uiHeight - warnHeight : uiHeight} >
                <Tab height={uiHeight}>
                    <StyledContainer height={uiHeight}>
                       {PageHeader(props,"The roadmap")}
                        <StyledMainPanel>
                            <StatusRow><Pulsar /> <StatusActive>Development phase <StatusIndicator>LIVE</StatusIndicator></StatusActive></StatusRow>
                            <StatusRow><Pulsar /><StatusActive>Greedy Bots </StatusActive><></></StatusRow>
                            <StatusRow><Pulsar /> <StatusActive>Testing </StatusActive></StatusRow>
                            <StatusRow><PulsNot /> <StatusPhase>Deployment </StatusPhase></StatusRow>
                            <StatusRow><PulsNot /> <StatusPhase>Airdrop </StatusPhase></StatusRow>
                            <StatusRow><PulsNot /> <StatusPhase>D👽O </StatusPhase></StatusRow>
                            <StatusRow><PulsNot /> <StatusPhase>Blockchain Of Things</StatusPhase></StatusRow>
                        </StyledMainPanel>
                    </StyledContainer>
                </Tab>
                {footer ? <Footer /> : "" }
            </Content>
        </PageWrapper>
    );
};

export default RoadMap;


const Logo = styled.img`


`;


const StyledMainPanel = styled.div`
display:inline-block;
top:0px;
width:66.5%;
height: fit-content;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;

const StatusIndicator = styled.span`
color:green;
`;

const pulse = keyframes`
0% {
    box-shadow: 0 0 0 1px rgba(128, 0, 128, 0.6);
  }
100% {
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0);
  }
`;

const Pulsar = styled.div`
display:inline-block;
transform-style: preserve-3d;
background: radial-gradient(circle farthest-corner at 33% 33%, rgba(0, 0, 0, 0.85) 0%, rgba(128, 0, 128, 0.85) 80%), radial-gradient(circle farthest-corner at 45% 45%, rgba(0, 0, 0, 0) 50%, #800080 80%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: ${pulse} 2s infinite;
`;

const PulsNot = styled.div`
display:inline-block;
transform-style: preserve-3d;
background: radial-gradient(circle farthest-corner at 33% 33%, rgba(0, 0, 0, 0.85) 0%, rgba(128, 0, 128, 0.85) 80%), radial-gradient(circle farthest-corner at 45% 45%, rgba(0, 0, 0, 0) 50%, #800080 80%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  
`;

const StatusPhase = styled.div`
 display:inline-block;
 font-weight:500;
    font-size:1.5em;
    margin:25px;
    color:${colors.theme.text};
`;

const StatusActive = styled.div`
 display:inline-block;
 font-weight:500;
    font-size:1.5em;
    margin:25px;
    color:${colors.foreground.primary};
`;
const StatusRow = styled.div`
position:relative;
margin-left:0px;
margin-right:auto;
margin:10px;
clear: both;
`;

const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    overflow-y:auto;
`;

const Tab = styled.div`
    background-image:url('./img/fibe.jpeg');
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    position:relative;
    top:0px;
    bottom:60px;
    width:100%;
     height:${(props) => props.height}px;
    overflow-y:auto;
`;
