import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"



const Npm = (cmd) => {
    return (
        <NpmCmd>
       {cmd}
        </NpmCmd>
    )
}

export default Npm
const NpmCmd = styled.div`
margin-top:10px;
margin-bottom:10px;
border-radius:5px;
padding:5px;
background-color:${colors.background.secondary};
`;
