import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"
import { closeWarning } from "../store/actions";

const Warning = (props) => {
    
    const { dispatch, t, warningData } = props;
    return (
        <WarningWrapper>
            <WarningCloseButton onClick={() => dispatch(closeWarning())}>
                X
            </WarningCloseButton>
            <WarningData>&#9888;&#65039; {t('warning.network')} {warningData}</WarningData>
        </WarningWrapper>
    );
  };

export default Warning;

const WarningWrapper = styled.div`
    position: relative;
    top:50px;
    width: 100%;
    text-align: center;
    height: 25px;
    font-weight: 500;
    border-bottom: 1px solid ${colors.background.secondary};
    background-color:${colors.black};
    color:${colors.text.navigator};
    z-index:9;
`;

const WarningCloseButton = styled.div`
    position: absolute;
    right:0px;
    width: 25px;
    margin-right: 40px;
    font-weight: 800;
    font-size: large;
    cursor: pointer;
    z-index:2;
`;

const WarningData = styled.p`
    position:absolute;
    top:0px;
    width:100%;
    margin:auto;
    line-height: 20px;
    z-index:1;
`;