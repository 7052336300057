import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container} from "react-bootstrap";
import {colors} from "../utils/Colors"
import PageHeader from "../components/PageHeader"
import { config } from "../utils/Config";

const TeamMember = (value) =>{

    return(
        <Member key={value[1].ens}>
       <Name> {value[1].name} </Name>
       <Title>{value[1].title}</Title>
       <ENS> {value[1].ens} </ENS>
       <Picture src={`./team/${value[1].photo}`} /> 
       <Background>{value[1].background}</Background>
       <Email><a href={value[1].email}>{value[1].email}</a></Email>
      </Member>
    )
}
const Name = styled.h3`
    color:${colors.foreground.primary};
    text-align:left;
    margin:5px;
    margin-bottom:0px;
    
`;
const Title = styled.div`
    color:${colors.foreground.primary};
    text-align:left;
    font-size:1em;
    margin:5px;
  
`;
const ENS = styled.div`
    color:${colors.foreground.primary};
    text-align:left;
    font-size:.7em;
    margin:5px;
    margin-top:0px;
    margin-bottom:0px;
`;

const Background = styled.p`
margin:5px;
text-align: left;
font-size: .7em;
color:#ffffff;
height:70px;
`;

const Email = styled.div`
position:absolute;
text-align:center;
bottom:5px;
width:100%;
color:${colors.theme.primary};
`;


const Picture = styled.img`
position:relative;
width:265px;
border-radius:145px;
`;

const Team = (props, footer) => {
  const {uiHeight} = props
        return (
            <PageWrapper>
                <Content height={uiHeight} >
                    <Tab height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props,"The team")}
                         {Object.entries(config.teamMember).map((value) => TeamMember(value))}
                    </StyledContainer>
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content>
            </PageWrapper>
        );
      };
    
export default Team;

const Member = styled.div`
    display:inline-block;
    position: relative;
    border-radius:10px;
    margin:20px;
    width:275px;
    height:500px;
    box-shadow: 2px 2px 5px ${colors.theme.primary};
    background-color: ${colors.background.secondary};
    vertical-align: top;
`;

const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    overflow-y:auto;
`;

const Tab = styled.div`
    position:relative;
    margin-bottom:0px;
    width:100%;
    height:${(props) => props.height}px;
    overflow-y:auto;
`;

const StyledContainer = styled(Container)`
    position:relative;
    margin:auto;
    height:auto;
    text-align: center;
`;


