import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/Colors"
import logo from './erc_lib.svg';
import { addRPC } from "../../store/interactions"
import AssetSelector from "../AssetSelector";

const Erclib = (props) => {
    return (
        <ErclibWrapper>
            <ErclibContainer>
           
            <label htmlFor="chain">Add a network to Metamask</label>

            <StyleSelector onChange={(e) => addRPC(e.target.value)} name="network" id="chain">
                <option value="0">Select network</option>
                <option value="1313161554">Aurora</option>
                <option value="1313161555">Aurora Testnet</option>

                <option value="43114">Avax</option>
                <option value="43113">Avax Fuji Testnet</option>

                <option value="56">Binance</option>
                <option value="97">Binance Testnet</option>

                <option value="137">Plygon</option>
                <option value="80001">Polygon Testnet</option>

                <option value="100">xDAI</option>
            </StyleSelector>

            <label htmlFor="Selector">Add an asset to Metamask</label>

            {AssetSelector(props, false)}

           <PoweredByWrapper> <a target="_blank" href="https://www.npmjs.com/package/@idecentralize/erclib"> <PoweredBy src={logo}/> </a></PoweredByWrapper>
            </ErclibContainer>

        </ErclibWrapper>
    );
};

export default Erclib;

const StyleSelector = styled.select`
margin:10px;
`;

const PoweredBy = styled.img`
position:absolute;;
bottom:0px;
right:0px;
height:20px;
margin:0px;
`;
const PoweredByWrapper = styled.div`
position:absolute;
bottom:0px;
right:0px;
height:20px;
width:100%;
text-align: right;
`;

const ErclibWrapper = styled.div`
position:relative;
border-radius:10px;
max-width:300px;
margin-right:0px;
margin-left:auto;
padding:5px;
background: linear-gradient(to right, #013474, purple);
`;

const ErclibContainer = styled.div`
position:relative;
padding:5px;
padding-bottom:15px;
border-radius:5px;
background:${colors.background.primary};
color:${colors.foreground.primary};
`;
