import { combineReducers } from 'redux';

function web3(state = {}, action) {
    switch(action.type){
        case 'LOAD_WEB3':
        return { ...state, loaded: true, instance: action.instance}
        case 'LOAD_ACCOUNT':
        return { ...state, account: action.account}
        case 'DISCONNECTED':
            return { ...state, account: null}
        case 'NETWORK_CHANGED':
            return { ...state, network: action.network}
        default:
            return state
    }

}

function nav(state = {}, action) {
    switch(action.type){
        case 'NAV_TOGGLE':
            return { ...state, toggle: true}
        case 'NAV_CLOSE':
            return { ...state, toggle:false}
        default:
            return state
    }
}

function warning(state = {}, action) {
    switch(action.type){
        case 'LOAD_WARNING':
            return { ...state, loaded: true, data: action.data}
        case 'CLOSE_WARNING':
            return { ...state, loaded: false,data: null}
        default:
            return state
    }
}


function nft(state = {}, action) {
    switch(action.type){
        case 'NFT_CONTRACT_LOADED':
            return { ...state, loaded: true, data: action.data}
        default:
            return state
    }
}

function spice(state = {}, action) {
    switch(action.type){
        case 'SPICE_PRICE':
            return { ...state, price: action.price}
        default:
            return state
    }
}

function asset(state = {}, action) {
    switch (action.type) {
        case 'ASSET_SELECTED_CHANGED':
            return { ...state, asset: action.asset, dropped: false }
        case 'ASSET_SELECTED_AMOUNT_CHANGED':
            return { ...state, adminAmount: action.adminAmount }
        case 'ASSET_AMOUNT_CHANGED':
            return { ...state, amount: action.amount }
        case 'SET_DEFAULT_ASSET':
            return { ...state, default: true }
        case 'ASSET_DROP':
            return { ...state, dropped: true }
        case 'ASSET_CLOSE':
            return { ...state, dropped: false }
        default:
            return state
    }
}

function ui(state = {}, action) {
    switch (action.type) {
        case 'WINDOW_RESIZE':
            return { ...state, height: action.height }
        default:
            return state
    }
}

function vault(state = {}, action) {
    switch (action.type) {
        case 'VAULT_LOADED':
            return { ...state, loaded:true, data: action.data }
        default:
            return state
    }
}


function greedy(state = {}, action) {
    switch (action.type) {
        case 'GREEDY_LOADED':
            return { ...state, loaded:true, greedyData: action.greedyData }
        case 'GREEDY_BALANCE':
            return { ...state, balance: action.balance }
        case 'GREEDY_URI':
                return { ...state, botUri: action.botUri }
        default:
            return state
    }
}

function dreambox(state = {}, action) {
    switch (action.type) {
        case 'DREAMBOX_LOADED':
            return { ...state, loaded:true, dreamboxData: action.dreamboxData }
        default:
            return state
    }
}



const rootReducer = combineReducers({
    web3,
    warning,
    nav,
    nft,
    spice,
    asset,
    ui,
    vault,
    greedy,
    dreambox

  })
  
  
  export default rootReducer