import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"
import {config} from "../utils/Config";
import logo from '../logo.svg';
import { Container } from "react-bootstrap";
import { FaTwitter, FaDiscord, FaGithub, FaFacebook, FaTelegram } from 'react-icons/fa';

const Footer = (props) => {
    return (
        <FooterWrapper>
         <Container>

            <ContainerRow>
                <FooterLogo src ={logo}/>
               
                <FooterSocials>
                    <SocialLogoWrapper> <SocialLink target="_blank" href={`https://facebook/${config.org.facebook}`}><FaFacebook/></SocialLink> </SocialLogoWrapper>
                    <SocialLogoWrapper> <SocialLink target="_blank" href={`https://twitter.com/${config.org.twitter}`}><FaTwitter/></SocialLink> </SocialLogoWrapper>
                    <SocialLogoWrapper> <SocialLink target="_blank" href={`https://github.com/${config.org.github}`}><FaGithub/></SocialLink> </SocialLogoWrapper>
                    <SocialLogoWrapper> <SocialLink target="_blank" href={`https://discord.gg/${config.org.discord}`}><FaDiscord/></SocialLink> </SocialLogoWrapper>
                    <SocialLogoWrapper> <SocialLink target="_blank" href={`https://telegram.org/${config.org.telegram}`}><FaTelegram/></SocialLink> </SocialLogoWrapper>
                </FooterSocials>
            </ContainerRow>

            <ContainerRow>
            <Sponsor src="https://github.com/sponsors/idecentralize-finance/button" title="Sponsor idecentralize-finance" height="35" width="116" ></Sponsor>
                <FooterOrganization>
                    Copyright © {config.org.name} 2021. All rights reserved.
                </FooterOrganization>
            </ContainerRow>

         </Container>
        </FooterWrapper>
    );
  };

export default Footer;

const ContainerRow = styled.div`
position:relative;
width:100%;
`;

const SocialLink = styled.a`
    text-decoration:none;
    font-size:50px;
    color: ${colors.text.primary};
    &:hover{
        text-shadow: 2px 2px 50px  ${colors.text.primary};
        color: ${colors.theme.primary};
    }
    &:active{
        text-shadow: 2px 2px 50px  ${colors.text.primary};
        color: ${colors.theme.primary};
    }
`;

const SocialLogoWrapper = styled.div`
    position:relative;
    margin:5px;
    display:inline;
    font-size:25px;
    clear:both;
`;

const FooterSocials = styled.div`
 
  position:relative;
  margin-right: 0px;
  margin-left:auto;
  text-align: right;
  width:fit-content;
`;

const FooterWrapper = styled.div`
    position:relative;
    bottom:0px;
    text-align:center;
    width: 100%;
    background-color:${colors.background.secondary};
    color:${colors.text.primary};
`;

const FooterLogo = styled.img`
    position:relative;
    float:left;
    clear:both;
    height:50px;  
    margin:5px;
`;

const FooterOrganization = styled.div`
    position:relative;
    font-weight:200;
    color:${colors.text.footer};
    text-align: right;
`;

const Sponsor = styled.iframe`
position:relative;
border-radius:10px;
margin-left:auto;
margin-right:auto;
margin-top: 25px;;
color: #ffffff;
background-color: #ffffff;
`;
