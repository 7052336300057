import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer"
import { Container} from "react-bootstrap";
import {colors} from "../utils/Colors"
import PageHeader from "../components/PageHeader"


const BOT = (props, footer) => {
  const {uiHeight} = props
        return (
            <PageWrapper>
                <Content height={uiHeight} >
                    <Tab height={uiHeight} >
                    <StyledContainer height={uiHeight}>
                        {PageHeader(props,"Blockchain Of Things")}
                    </StyledContainer>
                    </Tab>
                    {footer ? <Footer /> : "" }
                </Content >
            </PageWrapper>
        );
      };
    
export default BOT;
    

const PageWrapper = styled.div`
    position:relative;
    top:0px;
    bottom:0px;
    margin-bottom:0px;
`;

const Content = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const Tab = styled.div`
position:relative;
margin-bottom:0px;
width:100%;
height:${(props) => props.height}px;
overflow-y:auto;
`;

const StyledContainer = styled(Container)`
position:relative;
margin:auto;
height:auto;
`;


