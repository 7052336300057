import { createSelector } from 'reselect'
import { get } from 'lodash'

// NETWORK
const web3 = state => get(state, 'web3.loaded')
export const web3Selector = createSelector(web3, w3 => w3)
const web3Instance = state => get(state, 'web3.instance')
export const web3InstanceSelector = createSelector(web3Instance, w3i => w3i)
const account = state => get(state, 'web3.account',[])
export const accountSelector = createSelector(account, a => a)

// WARNING
const warning = state => get(state, 'warning.loaded', false)
export const warningSelector = createSelector(warning, w => w)
const warningData = state => get(state, 'warning.data', false)
export const warningDataSelector = createSelector(warningData, wd => wd)

// NAV
const navToggle = state => get(state, 'nav.toggle', false)
export const toggleSelector = createSelector(navToggle, nt => nt)


// NFT
const nftContract = state => get(state, 'nft.loaded', false)
export const nftContractSelector = createSelector(nftContract, nft => nft)
const nftContractData = state => get(state, 'nft.data', [])
export const nftContractDataSelector = createSelector(nftContractData, nftd => nftd)


// SPICE PRICE
const spicePrice = state => get(state, 'spice.price', [])
export const spicePriceSelector = createSelector(spicePrice, nftd => nftd)


/// ASSET SELECTOR
const assetDroped = state => get(state, 'asset.dropped', false)
export const assetDropedSelector = createSelector(assetDroped, sa => sa)
const assetAmount = state => get(state, 'asset.amount',0)
export const assetAmountSelector = createSelector(assetAmount, sa => sa)
const adminAmount = state => get(state, 'asset.adminAmount','')
export const adminAmountSelector = createSelector(adminAmount, sa => sa)
const assetSelected = state => get(state, 'asset.asset', false)
export const assetSelectedSelector = createSelector(assetSelected, sa => sa)
const assetDefault = state => get(state, 'asset.default', false)
export const assetDefaultSelector = createSelector(assetDefault, sa => sa)


// UI
const uiHeight = state => get(state,'ui.height', window.innerHeight )
export const uiHeightSelector = createSelector(uiHeight, uih => uih)


// VAULT
const vaultLoaded = state => get(state,'vault.loaded', false)
export const vaultLoadedSelector = createSelector(vaultLoaded, vl => vl)
const vaultData = state => get(state,'vault.data', [])
export const vaultDataSelector = createSelector(vaultData, vl => vl)


const greedyLoaded = state => get(state,'greedy.loaded', false)
export const greedyLoadedSelector = createSelector(greedyLoaded, gb => gb)

const greedyData = state => get(state,'greedy.greedyData', [])
export const greedyDataSelector = createSelector(greedyData, gb => gb)
