import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors"
import { formatAddress } from "../utils/helpers";
import { loadingWeb3 } from "../store/interactions";
import { BiPlug } from "react-icons/bi";
import ButtonsOutline from "./ButtonFlat";
import { NETWORK } from "@idecentralize/erclib";
import Identicon from 'react-identicons';

const icon = () => {
  return <BiPlug />;
};

const identicon = (string) => {
  return (
    <IdenticonWrapper>
      <Identicon size="40" string={string} />
    </IdenticonWrapper>
  )
};

const Account = (props) => {
  const { account, web3 } = props;
  return (
    <AccountWrapper>
      <AddressBorders>
        {web3 && account !== null ?
          <AddressWrapper target="_blank" href={`${NETWORK[account.chainId].explorer + account.address}`}>
            {account.name != null ? account.name : formatAddress(account.address)}
            <NetworkLogo src={`./assets/${NETWORK[account.chainId].chain}.svg`} />
            {account.avatar != '' ? <Avatar src={account.avatar} /> : identicon(account.address)}
          </AddressWrapper>
          :
          ConnectButton(props)}
      </AddressBorders>
    </AccountWrapper>
  )
}

export default Account

const ConnectButton = (props) => {
  const { dispatch } = props;
  return (
    <ButtonsOutline
      action={() => {
        loadingWeb3(dispatch);
      }}
      message="Connect"
      icon={icon}
      waitForRipple={true}
    />
  )
}

const AddressWrapper = styled.a`
display:inline-block;
text-decoration:none;
border-radius:5px;
padding:3px;
margin:3px;
background: ${colors.background.primary};
color:${colors.text.navigator};
`;

const AddressBorders = styled.div`
display:inline-block;
border-radius:10px;
width:auto;
background: linear-gradient(to right, #013474, purple);
`;

const AccountWrapper = styled.div`
    position: relative;
    text-align:center;
    margin:5px;
    color:${colors.text.navigator};
`;

const NetworkLogo = styled.img`
  position:absolute;
  top:5px;
  left:-25px;
  height:25px;
  width:25px;
  margin-left:5px;
  `;

const Avatar = styled.img`
position:absolute;
border-radius:22.5px;
top:0px;
right:-50px;
height:40px;
width:40px;
margin-left:5px;
`;

const IdenticonWrapper = styled.div`
position:absolute;
border-radius:22.5px;
top:0px;
right:-50px;
height:40px;
width:40px;
margin-left:5px;
`;