import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors";
import { ERC20 } from "@idecentralize/erclib";
import 'bootstrap/dist/css/bootstrap.min.css';
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  assetDropdown,
  assetClose,
  assetSelectedAmountChanged
} from "../store/actions"
import {

  loadSelectedAsset
} from "../store/interactions";

const Creator = (props, showAmount) => {
  const { dispatch, assetDrop, assetSelected, adminAmount, account } = props
  return (
    <VaultAdmin>
      <AdminRow>
        <SelectedAsset index={assetDrop ? 12 : 2}>
          {Assets(props, ERC20[account.chainId === 31337 ? 1 : account.chainId][assetSelected.address])}
          <DropDownButton onClick={(e) => assetDrop ? dispatch(assetClose()) : dispatch(assetDropdown())} ><RiArrowDropDownLine /></DropDownButton>
          <AssetDropDown index={4} height={assetDrop ? 'auto' : '0px'} >
            {Object.entries(ERC20[account.chainId === 31337 ? 1 : account.chainId]).map(([, value]) => Dropped_Assets(props, value, showAmount))}
          </AssetDropDown>
         { showAmount ? <AssetAmount placeholder="amount" value={adminAmount} onChange={(e) => dispatch(assetSelectedAmountChanged(e.target.value))} /> : null}
        </SelectedAsset>
      </AdminRow>

    </VaultAdmin>
  )
}
const VaultAdmin = styled.div`
position:relative;
align-items:center;
`;
const AdminRow = styled.div`
position:relative;
text-align:center;
`;
const AssetAmount = styled.input`
position:absolute;
top:25px;
height:20px;
right:0px;
margin-right:5px;
width:100px;
`;
const SelectedAsset = styled.div`
position:relative;
background-color:${colors.background.primary};
color:${colors.text.primary};
margin:auto;
width:200px;
height:50px;  
border:1px solid #000000;
z-index:${(props) => props.index};                  
`;
const DropDownButton = styled.div`
position:absolute;
display:flex;
top:-1px;
right:-20px;
width:20px;
height:50px;
color:${colors.theme.text};
border:1px solid ${colors.background.secondary};
border-top-right-radius:5px;
border-bottom-right-radius:5px;
align-items:center;
justify-content: center;
font-size:50px;
&:hover{
    cursor:pointer;
    font-weight:300;
    box-shadow: 2px 2px 5px ${colors.theme.primary};
  }
`;

const Assets = (props, data) => {
    const { dispatch, assetDrop } = props
  return (
    <DefaultAsset bottomLeftRadius={assetDrop ? 0 : 3}>
      <AssetsWrapper key={data.symbol}>
        <AssetLogoWrapper>
          <AssetLogo src={`./assets/${data.symbol}.svg`} />
        </AssetLogoWrapper>
        <AssetSymbol>{data.symbol}</AssetSymbol>
      </AssetsWrapper>
    </DefaultAsset>
  )
}
const Dropped_Assets = (props, data, showAmount) => {

  const { dispatch, assetDrop } = props
  return (
    <DroppedAsset key={data.symbol + 'd'}>
      <AssetsWrapper onClick={() => assetDrop ? loadSelectedAsset(dispatch, data, showAmount) : null}  >
        <AssetLogoWrapper>
          <AssetLogo src={`./assets/${data.symbol}.svg`} />
        </AssetLogoWrapper>
        <AssetSymbol>{data.symbol}</AssetSymbol>
      </AssetsWrapper>
    </DroppedAsset>
  )
}

const DroppedAsset = styled.div`
position:relative;
background-color:#ffffff;
&:hover {
    cursor:pointer;
    font-weight:500;
    background-color:${colors.background.translucid}
  }
`;
const DefaultAsset = styled.div`
background-color:#ffffff;
height:48px;
border-top-left-radius: 3px;
border-bottom-left-radius: ${(props) => props.bottomLeftRadius}px;
`;
const AssetLogo = styled.img`
height:35px;
margin-top:5px;
margin-left:auto;
margin-right:auto;
`;
const AssetSymbol = styled.div`
float:right;
height:20px;
width:130px;
margin:auto;
color:${colors.black};
`;
const AssetLogoWrapper = styled.div`
float:left;
width:60px;
text-align:center;
`;
const AssetDropDown = styled.div`
position:absolute;
border-left:1px solid #000000;
border-right:1px solid #000000;
border-bottom:1px solid #000000;
border-bottom-left-radius:5px;
border-bottom-right-radius:5px;
visibility:visible;;
top:48px;
left:-1px;
overflow-y:auto;
overflow-x:hidden;
width:200px;
align-items:center;
background-color:${colors.background.primary};
max-height:300px;
height:${(props) => props.height};
transition: .5s ease;
z-index:${(props) => props.index};
`;
const AssetsWrapper = styled.div`
position:relative;
width:200px;
height:50px;
margin:0px;
`;

const AssetSelector = (props, showAmount) => {
  const { assetDefault } = props
  return (
    <AssetSelectorWrapper>
      {assetDefault ? Creator(props, showAmount) : null}
    </AssetSelectorWrapper>
  );
};
export default AssetSelector;

const AssetSelectorWrapper = styled.div`
   position:relative;
   display:flex;
   align-items:center;
   justify-content: top;
   padding:0px;
   margin:10px;
   `;