import React from "react";
import styled from "styled-components";
import {colors} from "../utils/Colors"
import "./numerator.css"
import Odometer from 'react-odometerjs';
const Numerator = (value) => {

  

    return (
        <NumeratorWrapper>
            <NumeratorBorder>
            <Odometer value={value} format="( ddd).dd" />
            <StyleLogo src="./assets/SPICE.svg"/>
            </NumeratorBorder>
        </NumeratorWrapper>
    )
}

export default Numerator


const NumeratorWrapper = styled.div`
position:relative;
border-radius:10px;
padding:5px;
font-size: 20px;
background: linear-gradient(to right, #013474, purple);

`;

const StyleLogo = styled.img`
position:absolute;
height:40px;
right:-45px;
`;


const NumeratorBorder = styled.div`
text-decoration:none;
border-radius:5px;
padding-left:10px;
padding-right:10px;

background: ${colors.background.primary};
color:${colors.text.navigator};

`;

