///// BLOCKCHAIN ACCOUNT 
export function loadWeb3(instance) {
    return{
        type: 'LOAD_WEB3',
        instance
    }
}
export function networkChanged(network) {
    return{
        type: 'NETWORK_CHANGED',
        network
    }
}
export function loadAccount(account) {
    return{
        type: 'LOAD_ACCOUNT',
        account
    }
}
export function loadENS(ens) {
    return{
        type: 'LOAD_ENS',
        ens
    }
}
export function disconnected(){
    return{
        type: 'DISCONNECTED',
    }
}

//// WARNING COMPONENT
export function loadWarning(data) {
    return {
        type: 'LOAD_WARNING',
        data
    }
}
export function closeWarning() {
    return {
        type: 'CLOSE_WARNING', 
    }
}




///// ASSET SELECTOR COMPONENT
export function assetDropdown() {
    return {
        type: 'ASSET_DROP', 
    }
}
export function assetClose() {
    return {
        type: 'ASSET_CLOSE',
    }
}
export function assetAmountChanged(amount) {
    return {
        type: 'ASSET_AMOUNT_CHANGED',
        amount
    }
}
export function assetSelectedChanged(asset) {
    return {
        type: 'ASSET_SELECTED_CHANGED',
        asset
    }
}
export function assetSelectedAmountChanged(adminAmount) {
    return {
        type: 'ASSET_SELECTED_AMOUNT_CHANGED',
        adminAmount
    }
}
export function setDefaultAsset() {
    return {
        type: 'SET_DEFAULT_ASSET',
    }
}






export function navToggler() {
    return {
        type: 'NAV_TOGGLE',
    }
}

export function navClose() {
    return {
        type: 'NAV_CLOSE',
    }
}



/////////// NFT RELATED

export function nftContractLoaded(data){
    return {
        type: 'NFT_CONTRACT_LOADED',
        data
    }

}






export function setSpicePrice(price){
    return {
        type: 'SPICE_PRICE',
        price
    }

}




export function windowSize(height) {
    return {
        type: 'WINDOW_RESIZE',
        height
    }
}






////////////////////////////////////////////////
//swap


// asset and chain setters
export function swapChainA(chain) {
    return {
        type: 'SWAP_CHAIN_A',
        chain
    }
}

export function swapChainB(chain) {
    return {
        type: 'SWAP_CHAIN_B',
        chain
    }
}

export function swapAssetA(asset) {
    return {
        type: 'SWAP_ASSET_A',
        asset
    }
}

export function swapAssetB(asset) {
    return {
        type: 'SWAP_ASSET_B',
        asset
    }
}

//// reactive

export function assetA(asset) {
    return {
        type: 'SWAP_ASSET_B',
        asset
    }
}


/////////////////////////////////////////////// VAULT DATA
export function vaultLoaded(data) {
    return {
        type: 'VAULT_LOADED',
        data
    }
}

/////////////////////////////////////////////// VAULT DATA
export function greedyLoaded(greedyData) {
    return {
        type: 'GREEDY_LOADED',
        greedyData
    }
}


export function greedyBalance(balance) {
    return {
        type: 'GREEDY_BALANCE',
        balance
    }
}

export function greedyBotURI(botUri) {
    return {
        type: 'GREEDY_URI',
        botUri
    }
}


export function dreamboxLoaded(dreamboxData) {
    return {
        type: 'DREAMBOX_LOADED',
        dreamboxData
    }
}